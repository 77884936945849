import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AppState} from '../../reducers/reducer';
import {Store} from '@ngrx/store';
import {getUser} from '../../login/selectors/auth.selector';
import {lastValueFrom, take} from 'rxjs';
import {AccountModel} from '../../shared/models/account.model';
import {AzureRolesEnum} from '../../shared/enums/azure-roles.enum';
import {AbsolutePaths} from "../../shared/enums/route.enum";
import {MsalService} from "@azure/msal-angular";

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(public router: Router,
              private readonly MsalGuard: MsalWithRedirectGuard,
              private readonly store: Store<AppState>) {
  }

  async canActivate(route: ActivatedRouteSnapshot,
                    state: RouterStateSnapshot): Promise<boolean> {
    if (await this.MsalGuard.canActivate(route, state)) {
      const a$ = this.store.select(getUser).pipe(take(1));
      const data: AccountModel = await lastValueFrom(a$);
      return (data.idTokenClaims?.extension_Roles === AzureRolesEnum.admin)
    } else {
      await this.router.navigate([AbsolutePaths.adminHome]);
      return false;
    }
  }
}

@Injectable()
export class ActivatedGuard implements CanActivate {
  constructor(public router: Router,
              private readonly MsalGuard: MsalWithRedirectGuard,
              private readonly store: Store<AppState>) {
  }

  async canActivate(route: ActivatedRouteSnapshot,
                    state: RouterStateSnapshot): Promise<boolean> {
    if (await this.MsalGuard.canActivate(route, state)) {
      const a$ = this.store.select(getUser).pipe(take(1));
      const data: AccountModel = await lastValueFrom(a$);
      if (data.idTokenClaims?.extension_activated === true) {
        return true;
      } else {
        await this.router.navigate([AbsolutePaths.adminHome]);
        return false
      }
    } else {
      return false;
    }
  }
}

@Injectable()
export class MsalWithRedirectGuard implements CanActivate {
  constructor(private readonly msalService: MsalService,
              private readonly store: Store<AppState>,
              private readonly router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot,
                    state: RouterStateSnapshot): Promise<boolean> {
    const a$ = this.store.select(getUser).pipe(take(1));
    const data: AccountModel = await lastValueFrom(a$);
    if (data) {

      return true;
    } else {
      await this.router.navigate(["/login"], {queryParams: {returnUrl: state.url}})
      return false;
    }
  }
}


