import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CompanyModel} from '../../../admin/models/company.model';
import {HttpCallerService} from '../http-caller.service';
import {environment} from '../../../../environments/environment';
import {lastValueFrom} from 'rxjs';
import {RequestQueryBuilder} from "@nestjsx/crud-request";

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends HttpCallerService {

  constructor(
    protected readonly http: HttpClient,
  ) {
    super(http);
  }

  async getById(id: number): Promise<CompanyModel> {
    return this.get<CompanyModel>(`/company/${id}`);
  }

  async getAll(): Promise<Array<CompanyModel>> {
    return this.get<Array<CompanyModel>>(`/company`);
  }

  async getPage(limit = 50): Promise<Array<CompanyModel>> {
    const path = `${environment.backendBasePath}/company`;
    const params = {
      limit: limit,
    };
    const data$ = this.http.get<Array<CompanyModel>>(path, { params });
    return await lastValueFrom(data$);
  }

  async searchByName(name: string, limit = 50): Promise<Array<CompanyModel>> {
    const path = `${environment.backendBasePath}/company`;
    const searchQuery = { givenName: { $contL: name } };
    const params = {
      limit: limit,
      s: JSON.stringify(searchQuery),
    };
    const data$ = this.http.get<Array<CompanyModel>>(path, { params });
    return await lastValueFrom(data$);
  }

  async update(company: Partial<CompanyModel>): Promise<CompanyModel> {
    return this.patch<CompanyModel>(`/company/${company.id}`, company);
  }

  async create(company: Partial<CompanyModel>): Promise<CompanyModel> {
    return this.post<CompanyModel>(`/company`, company);
  }

  async getMany(query: string): Promise<CompanyModel[]> {
    return this.get<CompanyModel[]>(`/company?${query}`);
  }

  async isFiscalCodeAlreadyRegistered(fiscalCode: string, id?: number): Promise<boolean> {
    const qb = new RequestQueryBuilder();

    qb.search({fiscalCode, id: {$ne: id}});
    qb.select(['id']);

    const alreadyRegisteredCompanies= await this.getMany(qb.query());

    return !!alreadyRegisteredCompanies?.length;
  }

  async getByFiscalCode(fiscalCode: string): Promise<CompanyModel> {
    const qb = new RequestQueryBuilder();

    qb.search({fiscalCode});

    return (await this.getMany(qb.query()))?.[0];
  }
}
