import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AppState} from '../../reducers/reducer';
import {Store} from '@ngrx/store';
import {getUser} from '../../login/selectors/auth.selector';
import {lastValueFrom, take} from 'rxjs';
import {AccountModel} from '../../shared/models/account.model';
import {AzureRolesEnum} from '../../shared/enums/azure-roles.enum';
import {AbsolutePaths} from "../../shared/enums/route.enum";
import {MsalWithRedirectGuard} from "../../admin/guards/admin.guard";

@Injectable()
export class UserGuard implements CanActivate {
  constructor(public router: Router,
              private readonly MsalGuard: MsalWithRedirectGuard,
              private readonly store: Store<AppState>) {
  }

  async canActivate(route: ActivatedRouteSnapshot,
                    state: RouterStateSnapshot): Promise<boolean> {
    if (await this.MsalGuard.canActivate(route, state)) {
      const a$ = this.store.select(getUser).pipe(take(1));
      const data: AccountModel = await lastValueFrom(a$);
      const result = (data.idTokenClaims?.extension_Roles === AzureRolesEnum.user);

      if (result) {
        return true;
      } else if (data.idTokenClaims?.extension_Roles === AzureRolesEnum.admin) {
        await this.router.navigate([AbsolutePaths.adminHome]);
        return false;
      } else {
        return false;
      }
    } else {
      await this.router.navigate([AbsolutePaths.adminHome]);
      return false;
    }
  }
}

