import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditionCreateComponent} from './components/edition-create/edition-create.component';
import {MaterialModule} from "../shared/modules/material.module";
import {SharedModule} from "../shared/shared.module";
import {LessonModule} from "../lesson/lesson.module";
import {PreRegistrationModule} from "../pre-registration/pre-registration.module";
import {EditionTableComponent} from './components/edition-table/edition-table.component';
import {FullTableModule} from "@overflo-srl/full-table";
import {EditionEditComponent} from './components/edition-edit/edition-edit.component';
import {SubRegistrationModule} from "../sub-registration/sub-registration.module";
import {DocumentsToDownloadComponent} from './components/documents-to-download/documents-to-download.component';
import {EditionFormComponent} from './components/edition-form/edition-form.component';
import {DocumentModule} from "../document/document.module";
import {DocumentsComponent} from '../document/components/documents/documents.component';
import {StateVisualizerComponent} from './components/state-visualizer/state-visualizer.component';
import {ConfirmedEditionCardComponent} from './components/confirmed-edition-card/confirmed-edition-card.component';
import {RouterModule} from "@angular/router";
import { StatePassedPipe } from './pipes/state-passed/state-passed.pipe';
import { EditionStateLabelPipe } from './pipes/edition-state-label/edition-state-label.pipe';
import { EditionConfirmDialogComponent } from './components/edition-confirm-dialog/edition-confirm-dialog.component';
import {TraineeModule} from "../trainee/trainee.module";

@NgModule({
  declarations: [
    EditionCreateComponent,
    EditionTableComponent,
    EditionEditComponent,
    DocumentsToDownloadComponent,
    EditionFormComponent,
    DocumentsComponent,
    StateVisualizerComponent,
    ConfirmedEditionCardComponent,
    StatePassedPipe,
    EditionStateLabelPipe,
    EditionConfirmDialogComponent,
  ],
  exports: [
    ConfirmedEditionCardComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
    MaterialModule,
    LessonModule,
    PreRegistrationModule,
    FullTableModule,
    SubRegistrationModule,
    DocumentModule,
    TraineeModule
  ]
})
export class EditionModule {
}
