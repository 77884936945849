import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRegistrationLinkComponent } from './components/user-registration-link/user-registration-link.component';
import {SharedModule} from "../shared/shared.module";
import {RouterModule} from "@angular/router";
import {BrowserModule} from "@angular/platform-browser";
import {UserGuard} from "./guards/user.guard";

@NgModule({
  declarations: [
    UserRegistrationLinkComponent
  ],
  imports: [
      BrowserModule,
      CommonModule,
      SharedModule,
      RouterModule
  ],
  providers: [UserGuard]
})
export class UserModule { }
