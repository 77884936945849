import {TraineeModel, TraineeModelFactory} from "./trainee.model";
import {PreRegistrationStatesEnum} from "../../pre-registration/enums/pre-registration-states.enum";
import { TraineeDeclarationModel } from "src/app/trainee/models/trainee-declaration.model";
import {TraineeWorkContractModel, TraineeWorkContractModelFactory} from "./trainee-work-contract.model";

export interface WorkContractHandler{
  workContract: TraineeWorkContractModel;
}

export class PreRegistrationModel implements WorkContractHandler{
  id?: number;
  notes!: string;
  adminNotes?: string;
  usedFreeCourse!: boolean;
  emailDate?: Date | string;
  subscriptionDate!: Date | string;
  workContract!: TraineeWorkContractModel;
  traineeDeclaration?: TraineeDeclarationModel;
  state!: PreRegistrationStatesEnum;
  price?: number;
  previousRejections!: number;
}

export class PreRegistrationModelFactory{
  static createEmpty(): PreRegistrationModel{
    return {
      previousRejections: 0,
      notes: "",
      state: PreRegistrationStatesEnum.notHandled,
      subscriptionDate: new Date(),
      workContract: TraineeWorkContractModelFactory.createEmpty(),
      usedFreeCourse: false
    };
  }

  static createWithTraineeNameAndFiscalCode(traineeName: string, fiscalCode: string){
    const preReg = this.createEmpty();
    const trainee = TraineeModelFactory.createWithFirstName(traineeName);
    trainee.fiscalCode = fiscalCode;
    preReg.workContract.trainee = trainee;
    return preReg;
  }
}
