// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: true,
  enableMsal: true,
  azureAdTenantDomainName: 'sistemaediliziabrescia',
  azureAdClientId: 'e6a5d827-cc8a-40c5-aae9-cf585743be1a',
  azureAdCloudInstance: 'https://login.onmicrosoftonline.com/',
  azureAdTenantId: '211bf1aa-a89d-4218-8c8d-f7cf910a9a71',
  baseAppUrl: 'https://gecostaging.overflo.srl',
  azureAdKnownAuthorities: [`https://sistemaediliziabrescia.b2clogin.com/`],
  azureSingUpSignInEndpoint: 'https://sistemaediliziabrescia.b2clogin.com/sistemaediliziabrescia.onmicrosoft.com/B2C_1_SignInAndUp_Staging/',
  backendBasePath: 'https://gecostaging.overflo.srl/api/v1',
  azureAdCustomParametersClientId: '40172b90-0485-4a31-8f7a-bbfdb94426e0',
  monthsForPrevediCheck: 12,
  basePrevediPath: 'https://prevedi.check-cantiere.it/api/v1',
  prevediUser: 'geco',
  prevediPassword: 'Vo9ries#',
  dynamicsEsebPath: 'https://eseb.crm4.dynamics.com',
  dynamicsAppId: 'ae48859d-58ac-eb11-8236-000d3ab28971'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
