// Helper
import * as dayjs from "dayjs";
import {Moment} from "moment";
import {TraineeModel} from "../models/trainee.model";
import {TraineeWorkContractModel, TraineeWorkContractModelFactory} from "../models/trainee-work-contract.model";
import {PreRegistrationModel, PreRegistrationModelFactory, WorkContractHandler} from "../models/pre-registration.model";
import {SubRegistrationModel} from "../../sub-registration/models/sub-registration.model";
import { AccountModel } from "../models/account.model";
import {CompanyModel} from "../../admin/models/company.model";
import {SelectOptionModel} from "@overflo-srl/dynamic-form";

const StringIsNotNumber = (value: any) => isNaN(Number(value));

export const MILLISECONDS_IN_A_WEEK = 1000 * 60 * 60 * 24 * 7;

// Turn enum into array
export function StringEnumToArray(enumeration: any): any[] {
  return Object.keys(enumeration)
    .filter(StringIsNotNumber)
    .map(key => enumeration[key]);
}

export function getCurrentDateTime(date: string): string{
  const utc = getDayJsObj(date);

  return utc ? utc.format('DD/MM/YYYY HH:mm') : '';
}

export function getCurrentDate(date: string): string{
  const utc = getDayJsObj(date);

  return utc ? utc.format('DD/MM/YYYY') : '';
}

function getDayJsObj(date: string) {
  if(!date) return null;
  const convertedDate = new Date(date);

  return dayjs(convertedDate);
}

export function extractDataFromTime(time: string, isHour: boolean) : number | null {
  // time del tipo '10:00'
  if(!time) return null;
  if(!time.includes(":")) return null;

  const split = time.split(":");
  if(split.length != 2) return null;

  const index = isHour ? 0 : 1;
  const number = Number(split[index]);
  if (number >= 60 ){
    return null;
  }
  if (number >= 24 && isHour){
    return null;
  }
  return number;
}

export function setTimeFromDate(date: string | Date) : string | null {
  if(!date) return null;
  const dateDay = dayjs(date);
  const startHour = dateDay.get('hour');
  const startMin = dateDay.get('minutes');
  let startHourString = startHour.toString();
  let startMinString = startMin.toString();
  if(startMin < 10) startMinString = `0${startMinString}`;
  if(startHour < 10) startHourString = `0${startHourString}`;
  return `${startHourString}:${startMinString}`;
}

export function mapFormFieldDate (momentDate: Moment) : Date {
  return momentDate?.toDate();
}

export function prepareIdsArray(selectedLines: any[]): number[]{
  const ids = [];
  for (const reg of selectedLines){
    if (reg.id){
      ids.push(reg.id);
    }
  }
  return ids
}

export function getLastWorkContract(trainee: TraineeModel) : TraineeWorkContractModel{
  const workContracts = trainee.workContracts;
  if (!!(workContracts) && workContracts?.length > 0) {
    let result = workContracts[0];
    for (let i = 1; i < workContracts?.length; i++) {
      const currentElementCreationDate = workContracts[i]?.createdDate
      if (!!(result?.createdDate) && !!(currentElementCreationDate) && result.createdDate > currentElementCreationDate){
        result = workContracts[i];
      }
    }
    return result;
  }
  return TraineeWorkContractModelFactory.createEmpty();
}

export function getPreRegWithSameTrainee(preRegsArray: WorkContractHandler[], preRegToCheck: WorkContractHandler) {
  const preRegFiscalCode = preRegToCheck.workContract?.trainee?.fiscalCode;
  if (preRegFiscalCode) {
    return preRegsArray.find(x => !!(x?.workContract?.trainee?.fiscalCode) && x.workContract?.trainee?.fiscalCode === preRegFiscalCode);
  }
  return undefined;
}

export function cleanSubRegsFromUserKey(workContractHandlers: WorkContractHandler[]) {
  for (const subReg of workContractHandlers){
    const workContract = subReg.workContract as any;
    if (workContract["user"]) {
      delete workContract.user;
    }
  }
}

export function createPreRegToSend(result: TraineeWorkContractModel, accountModel: AccountModel, isUser: boolean): PreRegistrationModel{
  const newPreReg = PreRegistrationModelFactory.createEmpty();
  newPreReg.workContract = result;

  const companyFiscalCode = accountModel.idTokenClaims?.extension_fiscalCode;
  if (companyFiscalCode && isUser) {
    newPreReg.workContract.companyFiscalCode = companyFiscalCode;
  }
  cleanSubRegsFromUserKey([newPreReg]);
  return newPreReg;
}

export function getCompanyOption(company: CompanyModel): SelectOptionModel {
  return {
    key: company.givenName + (company.capeCode ? ` (${company.capeCode})` : ''),
    value: company.id.toString(),
    mnemonicId: company.givenName,
    description: ''
  }
}

export function deepCopy<T>(object: T): T {
  return JSON.parse(JSON.stringify(object));
}

export function getObjectsPropertyValuesIfDefined<T>(objectArray: T[], getPropertyFn: (obj: T) => any) {
  const values = [];

  for (const obj of objectArray) {
    const value = getPropertyFn(obj);

    if (value) {
      values.push(value);
    }
  }

  return values;
}
