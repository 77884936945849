import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MaterialModule} from "../shared/modules/material.module";
import {ExtendedModule, FlexModule} from "@angular/flex-layout";
import {SharedModule} from "../shared/shared.module";
import { TraineeTableComponent } from './components/trainee-table/trainee-table.component';
import {FullTableModule} from "@overflo-srl/full-table";
import { TraineeViewComponent } from './components/trainee-view/trainee-view.component';
import {TraineeSelectionComponent} from "./components/trainee-selection/trainee-selection.component";
import {TraineeRowComponent} from "./components/trainee-row/trainee-row.component";
import {TraineeCreationComponent} from "./components/trainee-creation/trainee-creation.component";
import {DocumentModule} from "../document/document.module";
import { TraineeFormComponent } from './components/trainee-form/trainee-form.component';
import { TraineeEditComponent } from './components/trainee-edit/trainee-edit.component';
import { TraineeEditionInsertComponent } from './components/trainee-edition-insert/trainee-edition-insert.component';
import { TraineeDeclarationsDialogComponent } from './components/trainee-declarations-dialog/trainee-declarations-dialog.component';
import {SubRegistrationModule} from "../sub-registration/sub-registration.module";
import {AlreadyBookedPipe} from "./pipes/already-booked.pipe";
import { TranslateTraineeCategoryPipe } from './pipes/translate-trainee-category/translate-trainee-category.pipe';
import { GetSubregistrationEditionStatePipe } from './pipes/get-subregistration-edition-state/get-subregistration-edition-state.pipe';
import { GetSubregistrationStateLabelPipe } from './pipes/get-subregistration-state-label/get-subregistration-state-label.pipe';
import {DynamicFormModule} from "@overflo-srl/dynamic-form";
import { HasToDisableFreeCoursePipe } from './pipes/has-to-disable-free-course/has-to-disable-free-course.pipe';
import { CompanyPreRegistrationsTableComponent } from './components/company-pre-registrations-table/company-pre-registrations-table.component';
import { CompanyPreRegsSummaryComponent } from './components/company-pre-regs-summary/company-pre-regs-summary.component';
import {
  CompaniesPreRegsSummariesComponent
} from "./components/companies-pre-regs-summaries/companies-pre-regs-summaries.component";
import { HasToHideFooterInfoPipe } from './pipes/has-to-hide-footer-info/has-to-hide-footer-info.pipe';

@NgModule({
  declarations: [
    TraineeSelectionComponent,
    TraineeCreationComponent,
    TraineeRowComponent,
    TraineeTableComponent,
    TraineeViewComponent,
    TraineeFormComponent,
    TraineeEditComponent,
    TraineeEditionInsertComponent,
    TraineeDeclarationsDialogComponent,
    AlreadyBookedPipe,
    TranslateTraineeCategoryPipe,
    GetSubregistrationEditionStatePipe,
    GetSubregistrationStateLabelPipe,
    HasToDisableFreeCoursePipe,
    CompanyPreRegistrationsTableComponent,
    CompanyPreRegsSummaryComponent,
    CompaniesPreRegsSummariesComponent,
    HasToHideFooterInfoPipe
  ],
  exports: [
    TraineeSelectionComponent,
    TraineeCreationComponent,
    TraineeTableComponent,
    CompaniesPreRegsSummariesComponent,
  ],
    imports: [
        CommonModule,
        NgxMatSelectSearchModule,
        ReactiveFormsModule,
        MaterialModule,
        FormsModule,
        SharedModule,
        FlexModule,
        ExtendedModule,
        FullTableModule,
        DocumentModule,
        SubRegistrationModule,
        DynamicFormModule,
    ],
  providers: [
    TranslateTraineeCategoryPipe,
  ]
})
export class TraineeModule { }
