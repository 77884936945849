import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PreRegistrationComponent} from './components/pre-registration/pre-registration.component';
import {SharedModule} from "../shared/shared.module";
import {PreRegistrationTableComponent} from './components/pre-registration-table/pre-registration-table.component';
import {FormsModule} from "@angular/forms";
import {FlexModule} from "@angular/flex-layout";
import {
  LocalCePreRegistrationComponent
} from './components/local-ce-pre-registration/local-ce-pre-registration.component';
import {
  NonLocalCePreRegistrationComponent
} from './components/non-local-ce-pre-registration/non-local-ce-pre-registration.component';
import {TraineeModule} from "../trainee/trainee.module";
import {
  PreRegistrationNotesAdderComponent
} from './components/pre-registration-notes-adder/pre-registration-notes-adder.component';
import {
  PreRegistrationTableSelectComponent
} from './components/pre-registration-table-select/pre-registration-table-select.component';
import {PendingPreRegCardComponent} from "./components/pending-pre-reg-card/pending-pre-reg-card.component";
import {RouterModule} from "@angular/router";
import { PreRegsPricesChangeDialogComponent } from './components/pre-regs-prices-change-dialog/pre-regs-prices-change-dialog.component';

@NgModule({
  declarations: [
    PreRegistrationComponent,
    PreRegistrationTableComponent,
    LocalCePreRegistrationComponent,
    NonLocalCePreRegistrationComponent,
    PreRegistrationTableSelectComponent,
    PreRegistrationNotesAdderComponent,
    PendingPreRegCardComponent,
    PreRegsPricesChangeDialogComponent,
  ],
  exports: [
    LocalCePreRegistrationComponent,
    NonLocalCePreRegistrationComponent,
    PreRegistrationTableSelectComponent,
    PendingPreRegCardComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
    FormsModule,
    FlexModule,
    TraineeModule
  ]
})
export class PreRegistrationModule {
}
